import axios from 'utils/axios';

import { formatAsLocalizedDateStringIgnoringTZ } from 'utils/date';
import { omitBy, isNull } from 'lodash';

export async function addMedication(patientId, { measurement, drugClass, name, dose, strength, route, frequency }) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/medication/new`, {
            measurement,
            drugClass,
            name,
            dose,
            strength,
            route,
            frequency
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function addHistoricalFillEntry(patientId, medicationId, { name, strength, fillDate, pickupDate, daysSupply }) {
    try {
        const response = await axios.post(
            `/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/medication/${medicationId}/historical`,
            { name, strength, fillDate, pickupDate, daysSupply }
        );
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getMedicationHistorical(patientId, medicationId) {
    try {
        const response = await axios.get(
            `/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/medication/${medicationId}/historical`
        );
        for (let i = 0; i < response.data.historical.length; i += 1) {
            const row = response.data.historical[i];
            row.fillDate = formatAsLocalizedDateStringIgnoringTZ(row.fillDate);
            row.pickupDate = formatAsLocalizedDateStringIgnoringTZ(row.pickupDate);
        }
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}

export async function getMedicationList(patientId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/medication/list`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}

export async function getMedication(patientId, medicationId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/medication/${medicationId}`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}

export async function submitRefillWorkflow(patientId, medicationId, refillWorkflow) {
    try {
        // only include actual values to match schema of the different states for this api
        const body = omitBy(refillWorkflow, isNull);
        const response = await axios.post(
            `/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/medication/${medicationId}/refill-workflow`,
            body
        );
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}
